<template>
  <!-- 服务与支持 -->
  <div id="service">
    <!--  头部banner区域  -->
    <div class="top_banner">
      <img src="@/assets/images/service/service_banner.png" alt="" />
      <div class="top_title">
        <div>
          <h3>服务与支持</h3>
          <!-- <p>Service And Support</p> -->
        </div>
      </div>
    </div>
    <!--  内容  -->
    <div class="content">
      <!--  服务解读  -->
      <div class="service_translate" id="interpretaion" ref="interpretaion">
        <div class="title">
          <h3>服务解读</h3>
          <!-- <p class="title_en">Service Interpretation</p> -->
        </div>
        <div class="service_translate_content">
          <img src="@/assets/images/service/service_translate.png" alt="" />
          <p>
            晓羊集团深度梳理公司后台流程，加强内控，引入并打造客户成功理念，创建一个高效的客户价值中心，全面确立基于3C服务理念：
            “来源于客户、创造于客户、服务于客户”（OF THE CLIENT,BY THE
            CLIENT,FOR THE CLIENT）的Service 2.0 服务品牌。
          </p>
        </div>
      </div>
      <!--  服务能力  -->
      <div class="service_ability" id="ability" ref="ability">
        <div class="title">
          <h3>服务能力</h3>
          <!-- <p class="title_en">Service Capabilities</p> -->
        </div>
        <div class="service_ability_content">
          <img :src="serviceAbilityPc" alt="" />
        </div>
      </div>
      <!--  服务地图  -->
      <div class="service_map" id="map" ref="map">
        <div class="title">
          <h3>服务地图</h3>
          <!-- <p class="title_en">Service Map</p> -->
        </div>
        <div class="service_map_content">
          <img src="@/assets/images/service/service_map_n.png" alt="" />
          <ul class="sub_company">
            <li>
              <div>
                <span class="color-primary"
                  >集团总部：北京晓羊科技集团有限公司</span
                >
                <p>{{ $store.state.baseInfo.address }}</p>
              </div>
            </li>
            <li>
              <div>
                <span class="color-primary">集团第二总部与集团运营中心</span>
                <p>四川省成都市高新区人工智能中区创新中心B7栋18层</p>
              </div>
            </li>
            <li v-for="(item, index) in subList" :key="index">
              <div>
                <span>{{ item.name }}</span>
                <p>{{ item.address }}</p>
              </div>
            </li>
          </ul>
          <h4 class="color-primary">办事处地址</h4>
          <ul class="address_tel">
            <li v-for="(item, index) in addressList" :key="index">
              <div>
                <span>{{ item.name }}</span>
                <!-- <p>电话：{{ item.tel }}</p> -->
                <p v-html="item.address"></p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import serviceAbilityPc from '@/assets/images/service/service_ability_pc.png'
import serviceAbilityMobile from '@/assets/images/service/service_ability_mobile.png'
export default {
  name: 'ServiceSupport',
  data() {
    return {
      serviceAbilityPc,
      serviceAbilityMobile,
      subList: [],
      addressList: [],
    }
  },
  methods: {
    // 获取子公司列表
    getSubDataList() {
      this.subList = [
        {
          name: '北京阅羊科技有限公司',
          address: '北京市海淀区中关村互联网教育创新中心903室',
        },
        {
          name: '北京晓羊策学科技有限公司',
          address: '北京市海淀区中关村大街18号B座935',
        },
        {
          name: '北京牧迪科技有限公司',
          address: '北京市海淀区西小口路18号新华创新大厦2层2009室',
        },
        {
          name: '北京晓羊和讯科技有限公司',
          address: '北京市昌平区沙河镇百沙路富雷科技院内2-106',
        },
        {
          name: '晓羊（天津）信息科技有限公司',
          address: '天津市红桥区闸桥北路金联公寓16门底商',
        },
        {
          name: '江苏晓羊信息技术有限公司',
          address: '南京市江宁区南佑路7号翠创大厦4018室',
        },
        {
          name: '无锡晓羊信息科技有限公司',
          address: '无锡市锡山区安镇街道山河路50-42-601、602',
        },
        {
          name: '湖南晓羊一数科技有限公司',
          address: '长沙市高新开发区文轩路27号麓谷钰园F4栋2202室',
        },
        {
          name: '湖南大汉工匠研学实践教育服务有限公司',
          address: '长沙市望城区大汉金桥未来城5栋一楼大汉研学公司',
        },
        {
          name: '广西晓羊科技有限公司',
          address: '南宁市青秀区东葛路延长线118号青秀万达广场甲2栋25楼2519室',
        },
        {
          name: '柳州市领羊基础教育发展与创新研究院',
          address: '柳州市阳光壹佰城市广场25栋20层6号',
        },
        {
          name: '重庆晓羊智教信息技术有限公司',
          address: '重庆市渝北区互联网产业园9栋6楼002',
        },
        {
          name: '合肥聚力研学智能营地教育科技有限公司',
          address: '合肥市肥东县桥头集镇太平社区VR小镇展示体验中心',
        },
        {
          name: '四川晓羊星网云动科技有限公司',
          address: '四川省成都市高新区AI创新中心B7栋18楼',
        },
        {
          name: '四川智优羊科技有限公司',
          address: '成都市武侯区西部智谷D区1栋3单元706',
        },
        {
          name: '福建晓羊教育科技有限公司',
          address: '福州市仓山区杨周路21号钱隆汇金中心2号楼105-1',
        },
        {
          name: '广州晓羊育科技术有限公司',
          address: '广东省广州市越秀区越秀中路125大院',
        },
        {
          name: '郑州晓羊队建信息技术有限公司',
          address: '河南省郑州市管城回族区郑东升龙广场3号楼B座9层',
        },
      ]
    },
    // 获取办事处地址列表
    getAddressDataList() {
      this.addressList = [
        {
          name: '河北省办事处 ',
          tel: '13681593469',
          address: '石家庄市裕华区富强大街北大科技园7层',
        },
        {
          name: '甘肃省办事处 ',
          tel: '18609312334',
          address: ' 兰州市城关区金昌北路25号亚太大厦2502室',
        },
        {
          name: '海南省办事处 ',
          tel: '13876315619',
          address: '海口市龙华区金垦路58号',
        },
        {
          name: '陕西省办事处 ',
          tel: '13311058863',
          address: '西安市雁塔区朱雀大街南段19号南方星座B座2809室',
        },
        {
          name: '河南省办事处 ',
          tel: '13849107285',
          address: '郑州市中原区建设路11号鑫苑国际广场一号楼一单元1005室',
        },
        {
          name: '新疆办事处 ',
          tel: '',
          address: '乌鲁木齐市水磨沟区南湖东路青新3巷89号君悦大厦1栋3层301',
        },
        {
          name: '内蒙古办事处 ',
          tel: '18686075007',
          address: '呼和浩特市赛罕区银河北路恒大华府2期9号楼一单元902',
        },
        {
          name: '四川省办事处 ',
          tel: '18683615500',
          address: '成都市锦江区绿地中心468锦峰2201',
        },
        {
          name: '山西省办事处 ',
          tel: '13311058863',
          address: '太原市小店区文锦世家西区H座2单元502室',
        },
        {
          name: '江西省办事处 ',
          tel: '13717714383',
          address: '南昌市红谷滩新区世贸路1001号世贸元亨大厦704',
        },
        {
          name: '广东省办事处 ',
          tel: '15622733097',
          address:
            '广州市海珠区新港西路粤民大厦1314<br/>深圳市南山区朗山路8号东物商业大厦608',
        },
        {
          name: '西藏办事处 ',
          tel: '',
          address:
            '西藏自治区拉萨市城关区江冲路15号世邦·东城<br/>1号1栋一单元1104室',
        },
        {
          name: '山东省办事处 ',
          tel: '13335078221',
          address: '济南市市中区万达广场B座916室',
        },
      ]
    },
  },
  mounted() {
    this.getSubDataList()
    this.getAddressDataList()
    this.$nextTick(() => {
      // console.log()
      // // window.location.reload()
      setTimeout(() => {
        window.scroll({
          top: this.$refs[this.$route.hash.replace('#', '')].offsetTop - 145,
          left: 0,
          behavior: 'smooth',
        })
      }, 100)
    })
  },
}
</script>

<style lang="scss" scoped>
%service {
  // 头部banner
  .top_banner {
    position: relative;
    margin-top: 125px;
    margin-bottom: 50px;
    img {
      width: 100%;
    }
    .top_title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 42px 100px;
      background-color: rgba(0, 0, 0, 0.5);
      color: $--color-white;
      text-align: center;
      h3 {
        margin: 0;
        font-size: 56px;
        @include px2vw('font-size', 56);
      }
      p {
        font-size: 32px;
        margin-bottom: 0;
        @include px2vw('font-size', 32);
      }
    }
  }
  .content {
    width: 90%;
    max-width: 1320px;
    margin: 0 auto;
    // 通用头部样式
    .title {
      text-align: center;
      color: #333;
      h3 {
        // line-height: 60px;
        font-size: 36px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 24px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .service_translate {
      width: 100%;
      margin: 0 auto;
      &_content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        background-color: #f7f7f8;
        padding: 40px 50px;
        img {
          width: 500px;
          margin-right: 30px;
        }
        p {
          width: calc(100% - 530px);
          line-height: 32px;
          font-size: 18px;
          text-align: justify;
          color: #666;
        }
      }
    }
    .service_ability,
    .service_map {
      margin: 120px 0;
      // padding: 120px 0 0;
      img {
        width: 70%;
        display: block;
        margin: 20px auto;
      }
    }
    .service_ability {
      margin: 50px auto;
      img {
        width: 100%;
        display: block;
        margin: 50px auto;
      }
    }
    .service_map {
      margin: 50px auto;
      &_content {
        .service_HQ {
          span.color-primary {
            font-weight: bold;
          }
        }

        h4 {
          margin: 40px 0;
          font-size: 24px;
        }
        ul {
          margin-top: 40px;
          display: grid;
          grid-template-columns: repeat(2, 50%);
          span {
            font-weight: bold;
          }
          p {
            line-height: 32px;
            font-size: 16px;
            text-align: justify;
            color: #666;
          }
        }
      }
    }
  }
}

.w-f-2 {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  .w-f-2-1 {
    display: flex;
    // justify-content: flex-end;
  }
  .service_HQ {
    min-width: 460px;
  }
}

.view-pc > #service {
  @extend %service;
  .pc {
    display: inherit;
  }
  .mobile {
    display: none;
  }
  .service_map {
    .service_HQ {
    }
    &_content {
      img {
        width: 100%;
        margin: 50px auto !important;
      }
      .sub_company,
      .address_tel {
        li {
          display: flex;
          > div {
            min-width: 460px;
          }
          &:nth-of-type(even) {
            justify-content: flex-end;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1100px) {
    .top_banner {
      margin-top: 125px;
    }
    .content {
      width: 90%;
      margin: 0 auto;
      .service_translate {
        img {
          width: 250px;
        }
        p {
          font-size: 20px;
          line-height: 32px;
          width: calc(100% - 280px);
        }
      }
      .service_ability {
        img {
          width: 100%;
        }
      }
      .service_map {
        &_content {
          img {
            width: 100%;
          }
        }
      }
    }
  }
  @media (min-width: 570px) and(max-width:1440px) {
    .service_ability {
      img {
        // margin-top: -25px !important;
        // width: 85% !important;
      }
    }
    .service_map {
      &_content {
        img {
          width: 100%;
          margin-top: -20px !important;
        }
      }
    }
  }
}

.view-mobile > #service {
  @extend %service;
  .pc {
    display: none;
  }
  .mobile {
    display: inherit;
  }
  overflow: hidden;
  margin-top: 44px;
  .top_banner {
    margin-bottom: 0;
    margin-top: 0;
    .top_title {
      width: 100%;
      height: 100%;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      > div {
        // position: absolute;
        // left: 40%;
        // top: 40%;
        // transform: translate(-40%, -40%);
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px 40px;
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding: 0 10px;
    .title_en {
      font-size: 12px !important;
      margin-top: 10px;
    }
    h3 {
      line-height: 30px !important;
    }
    .title {
      text-align: center;
      color: #333;
      margin-top: 20px;
      h3 {
        line-height: 60px;
        font-size: 40px;
        font-weight: bold;
        margin: 0;
      }
      .title_en {
        font-size: 18px;
      }
      .title_en::after {
        content: '';
        display: block;
        width: 66px;
        height: 3px;
        margin: 20px auto;
        background-color: $--color-primary;
      }
    }
    .service_translate {
      &_content {
        display: block;
        margin-top: 20px;
        padding: 30px;
        img,
        p {
          width: 100%;
          font-size: 14px;
          line-height: 28px;
          margin-bottom: 0;
        }
      }
    }
    .service_ability {
      .title {
        margin-top: 30px;
      }
      &_content {
        // margin-top: 0;
        img {
          margin: 30px auto;
        }
      }
      margin: 0;
      img {
        width: 100%;
      }
    }
    .service_map {
      margin-top: 0;
      margin-bottom: 20px;
      .title {
        margin-top: 5px;
      }
      &_content {
        p {
          font-size: 12px;
          line-height: 14px;
        }
        img {
          width: 100%;
        }
        h4 {
          margin: 20px 0;
          font-size: 20px;
        }
        ul {
          margin-top: 40px;
          display: block;
          li {
            font-size: 12px;
            line-height: 14px;
            p {
              font-size: 12px;
              line-height: 14px;
            }
          }
          span {
            font-weight: bold;
          }
        }
        .sub_company {
          margin-top: 20px;
        }

        .address_tel {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
