var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"service"}},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{ref:"interpretaion",staticClass:"service_translate",attrs:{"id":"interpretaion"}},[_vm._m(1),_vm._m(2)]),_c('div',{ref:"ability",staticClass:"service_ability",attrs:{"id":"ability"}},[_vm._m(3),_c('div',{staticClass:"service_ability_content"},[_c('img',{attrs:{"src":_vm.serviceAbilityPc,"alt":""}})])]),_c('div',{ref:"map",staticClass:"service_map",attrs:{"id":"map"}},[_vm._m(4),_c('div',{staticClass:"service_map_content"},[_c('img',{attrs:{"src":require("@/assets/images/service/service_map_n.png"),"alt":""}}),_c('ul',{staticClass:"sub_company"},[_c('li',[_c('div',[_c('span',{staticClass:"color-primary"},[_vm._v("集团总部：北京晓羊科技集团有限公司")]),_c('p',[_vm._v(_vm._s(_vm.$store.state.baseInfo.address))])])]),_vm._m(5),_vm._l((_vm.subList),function(item,index){return _c('li',{key:index},[_c('div',[_c('span',[_vm._v(_vm._s(item.name))]),_c('p',[_vm._v(_vm._s(item.address))])])])})],2),_c('h4',{staticClass:"color-primary"},[_vm._v("办事处地址")]),_c('ul',{staticClass:"address_tel"},_vm._l((_vm.addressList),function(item,index){return _c('li',{key:index},[_c('div',[_c('span',[_vm._v(_vm._s(item.name))]),_c('p',{domProps:{"innerHTML":_vm._s(item.address)}})])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_banner"},[_c('img',{attrs:{"src":require("@/assets/images/service/service_banner.png"),"alt":""}}),_c('div',{staticClass:"top_title"},[_c('div',[_c('h3',[_vm._v("服务与支持")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("服务解读")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service_translate_content"},[_c('img',{attrs:{"src":require("@/assets/images/service/service_translate.png"),"alt":""}}),_c('p',[_vm._v(" 晓羊集团深度梳理公司后台流程，加强内控，引入并打造客户成功理念，创建一个高效的客户价值中心，全面确立基于3C服务理念： “来源于客户、创造于客户、服务于客户”（OF THE CLIENT,BY THE CLIENT,FOR THE CLIENT）的Service 2.0 服务品牌。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("服务能力")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("服务地图")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',[_c('span',{staticClass:"color-primary"},[_vm._v("集团第二总部与集团运营中心")]),_c('p',[_vm._v("四川省成都市高新区人工智能中区创新中心B7栋18层")])])])
}]

export { render, staticRenderFns }